import { User } from "@/graphql/generated/apollo/graphql"
import { Icon } from "@/ui/icon"
import { ReadMore } from "@/ui/readmore"
import moment from "moment"
import ProfileSocialMedia, { socialMediaIcons } from "./SocialMedia"
import { cn } from "@/utils/cn"

interface ProfileBioProps {
  user: User
  className?: string
}

export const ProfileBio = ({
  user,
  className,
}: ProfileBioProps): JSX.Element => {
  return (
    <div
      className={cn(
        "rounded-md",
        "border",
        "border-sr-border-quaternary",
        className
      )}
    >
      <div className="flex flex-col justify-center">
        <div
          className={`
            flex h-12 items-center gap-x-2 px-5 text-sm text-typography-grey-1
            lg:h-16
          `}
        >
          <Icon
            name="logo"
            invertOnDark
            className="inline-block size-3 opacity-50"
          />{" "}
          Joined {moment(user.created_at).format("MMMM YYYY")}
        </div>
        {user.bio && (
          <div className="px-5 pb-5">
            <ReadMore length={250}>{user.bio}</ReadMore>
          </div>
        )}
      </div>
      {Object.entries(socialMediaIcons).some(
        ([key]) => user[key as keyof User]
      ) && (
        <div className="flex items-center justify-between rounded-b-sm bg-sr-bg-quaternary p-5">
          <ProfileSocialMedia profile={user} />
        </div>
      )}
    </div>
  )
}
