"use client"

import ProfileHeroCard from "@/components/cards/ProfileHeroCard"
import { ProfileBio } from "@/components/profile/Bio"
import { ProfileStats } from "@/components/profile/Stats"
import { ProfileUser } from "@/components/profile/User"
import { Erc721Token, User } from "@/graphql/generated/apollo/graphql"
import { useProfileStore } from "@/web/stores/profile"
import { useEffect } from "react"

interface ProfileHeroProps {
  profile: User
  artwork?: Erc721Token | null
}

export default function ProfileHero({
  profile,
  artwork,
}: ProfileHeroProps): JSX.Element {
  const { mastheadArtwork, setMastheadArtwork } = useProfileStore()
  useEffect(() => {
    setMastheadArtwork(artwork || null)
  }, [artwork])

  return (
    <div
      className={`
        grid
        lg:grid-cols-2 lg:gap-x-64
      `}
    >
      <div
        className={`
          flex flex-col gap-y-12
          lg:gap-y-12
        `}
      >
        <ProfileUser user={profile} />
        {mastheadArtwork && (
          <>
            <ProfileStats
              className={`
                mb-3
                lg:mb-0
              `}
              user={profile}
            />
            <ProfileBio
              className={`
                -mt-8 hidden
                lg:block
              `}
              user={profile}
            />
          </>
        )}
      </div>
      <div
        className={`
          flex flex-col
          lg:gap-y-4
        `}
      >
        {mastheadArtwork ? (
          <>
            <ProfileHeroCard profile={profile} artwork={mastheadArtwork} />
            <ProfileBio
              className={`
                mt-3
                lg:hidden
              `}
              user={profile}
            />
          </>
        ) : (
          <>
            <ProfileStats
              className={`
                mt-12
                lg:mt-0
              `}
              user={profile}
            />
            <ProfileBio
              className={`
                mt-4
                lg:mt-0
              `}
              user={profile}
            />
          </>
        )}
      </div>
    </div>
  )
}
