import { User } from "@/graphql/generated/apollo/graphql"
import { cn } from "@/utils/cn"
import { Icon, IconType } from "@/ui/icon"

export const socialMediaIcons: { [key: string]: IconType } = {
  website: "globe",
  instagramlink: "instagram",
  twitterlink: "x",
  steemitlink: "globe",
  // spotifylink: faSpotify,
  // soundcloudlink: faSoundcloud,
  // tiktoklink: faTiktok,
  openSeaLink: "openSea",
  gitHub: "github",
  facebooklink: "facebook",
  // redditlink: faReddit,
  discordlink: "discord",
  youtubelink: "youtube",
  farcaster: "farcaster",
}

type SocialMediaKeys =
  | "website"
  | "instagramlink"
  | "twitterlink"
  | "steemitlink"
  | "spotifylink"
  | "soundcloudlink"
  | "tiktoklink"
  | "facebooklink"
  | "redditlink"
  | "discordlink"
  | "youtubelink"
  | "snapchatlink"
  | "clubhouselink"

const cleanWebsite = (website: string): string => {
  let cleanUrl = website.replace(
    /(^\w+:|^)\/\/|www\.|\.com|\.net|\.org|\.info|\.app|\.io|\.co|\.uk|\/$|\/\w+\/?$|\/\w+\.\w+\/?$/,
    ""
  )
  if (cleanUrl.length > 15) {
    cleanUrl = `${cleanUrl.substring(0, 15)}...`
  }
  return cleanUrl
}

// check if user did input http:// or https://
const checkWebsiteUrl = (website: string): string => {
  if (website.includes("http://") || website.includes("https://")) {
    return website
  }
  return `https://${website}`
}

const ProfileSocialMedia = ({
  profile,
}: {
  profile: User & { farcaster?: string }
}): JSX.Element => {
  return (
    <div className="flex items-center gap-x-2">
      {Object.entries(socialMediaIcons).map(([key, iconName]) => {
        const link = profile[key as SocialMediaKeys]
        if (link) {
          return (
            <a
              href={
                key === "farcaster"
                  ? `https://warpcast.com/${link}`
                  : checkWebsiteUrl(link)
              }
              key={key}
              className={cn(
                `
                  flex items-center justify-center rounded-md border border-sr-border-tertiary text-sr-text-primary
                  dim:hover:border-white
                  hover:border-sr-border-primary
                `,
                key !== "website" && "size-7",
                key === "website" &&
                  `
                    h-7 px-2
                    lg:px-4
                  `
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <Icon name={iconName} invertOnDark />
              </span>
              {key === "website" && (
                <span
                  className={`
                    ml-2 hidden
                    lg:inline
                  `}
                >
                  {cleanWebsite(link)}
                </span>
              )}
            </a>
          )
        }
        return null
      })}
    </div>
  )
}

export default ProfileSocialMedia
