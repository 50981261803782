"use client"

import { cn } from "@/utils/cn"
import { useState } from "react"
import parse from "html-react-parser"
import DOMPurify from "dompurify"
import { stripHtml } from "@/utils/stripHtml"
import { convertFromRaw } from "draft-js"
import { stateToHTML } from "draft-js-export-html"

export const ReadMore = ({
  children,
  length = 100,
  className,
  html = false,
  tryParsing = false,
}: {
  children: React.ReactNode
  length?: number
  className?: string
  html?: boolean
  tryParsing?: boolean
}): JSX.Element => {
  const [isTruncated, setIsTruncated] = useState(true)
  const toggleIsTruncated = (): void => setIsTruncated(!isTruncated)

  const truncate = (input: string, length: number): string => {
    const strippedInput = stripHtml(input)
    if (strippedInput.length > length) {
      const truncatedStripped = strippedInput.substring(0, length)
      const endIndex =
        input.indexOf(truncatedStripped) + truncatedStripped.length
      return input.substring(0, endIndex) + "..."
    }
    return input
  }

  let parsedContent = children?.toString() || ""

  if (tryParsing) {
    try {
      const jsonContent = JSON.parse(parsedContent)
      if (jsonContent.blocks && jsonContent.entityMap) {
        const contentState = convertFromRaw(jsonContent)
        parsedContent = stateToHTML(contentState)
        html = true
      }
    } catch (error) {
      //console.error("Failed to parse content:", error)
    }
  }

  const content = isTruncated ? truncate(parsedContent, length) : parsedContent
  const sanitizedContent = html
    ? DOMPurify.sanitize(content, { FORBID_ATTR: ["style"] })
    : content

  return (
    <div
      className={cn(
        `
          flex
          [&_a]:hover:underline
        `,
        className
      )}
    >
      <div>
        {html ? parse(sanitizedContent) : sanitizedContent}
        {stripHtml(parsedContent).length > length && (
          <button
            className="w-full pr-2 text-right underline"
            onClick={toggleIsTruncated}
          >
            {isTruncated ? "Read More" : "Show Less"}
          </button>
        )}
      </div>
    </div>
  )
}
