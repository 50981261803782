import(/* webpackMode: "eager" */ "/app/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/context.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useAccount.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useAccountEffect.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useBalance.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useBlock.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useBlockNumber.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useBlockTransactionCount.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useBytecode.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useCall.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useChainId.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useChains.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useClient.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useConfig.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useConnect.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useConnections.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useConnectorClient.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useConnectors.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useDeployContract.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useDisconnect.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useEnsAddress.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useEnsAvatar.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useEnsName.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useEnsResolver.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useEnsText.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useEstimateFeesPerGas.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useEstimateGas.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useEstimateMaxPriorityFeePerGas.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useFeeHistory.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useGasPrice.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useInfiniteReadContracts.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/usePrepareTransactionRequest.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useProof.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/usePublicClient.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useReadContract.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useReadContracts.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useReconnect.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useSendTransaction.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useSignMessage.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useSignTypedData.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useSimulateContract.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useStorageAt.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useSwitchAccount.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useSwitchChain.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useToken.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useTransaction.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useTransactionConfirmations.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useTransactionCount.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useTransactionReceipt.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useVerifyMessage.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useVerifyTypedData.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useWaitForTransactionReceipt.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useWalletClient.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useWatchAsset.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useWatchBlockNumber.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useWatchBlocks.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useWatchContractEvent.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useWatchPendingTransactions.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hooks/useWriteContract.js");
import(/* webpackMode: "eager" */ "/app/node_modules/wagmi/dist/esm/hydrate.js");
import(/* webpackMode: "eager" */ "/app/src/app/profile/[username]/hero.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/profile/[username]/nav.tsx")