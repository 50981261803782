"use client"

import { User } from "@/graphql/generated/apollo/graphql"
import { ReactNode, useState } from "react"
import { UserList } from "./UserList"
import { Icon } from "@/ui/icon"
import { cn } from "@/utils/cn"
import { FollowsDialog } from "./FollowsDialog"
import { useToggle } from "react-use"

interface ProfileUserProps {
  user: User
  className?: string
}

const Stat = ({
  label,
  value,
  onClick,
}: {
  label: string
  value: string | ReactNode
  onClick?: () => void
}): JSX.Element => (
  <div
    onClick={onClick}
    className={cn(
      "group flex w-full items-center gap-x-3",
      onClick && "cursor-pointer"
    )}
  >
    <p className="text-sm uppercase tracking-wider text-typography-grey-2">
      {label}
    </p>
    <p className={cn("text-base", onClick && "group-hover:underline")}>
      {value}
    </p>
    {onClick && (
      <Icon
        invertOnDark
        name="arrowTopRight"
        className={`
          ml-auto translate-x-5 opacity-0 duration-300
          group-hover:translate-x-0 group-hover:opacity-100
        `}
      />
    )}
  </div>
)

export const ProfileStats = ({
  user,
  className,
}: ProfileUserProps): JSX.Element => {
  const [modalKind, setModalKind] = useState<
    "followers" | "following" | "collectors"
  >("followers")
  const [modalOpen, toggleModalOpen] = useToggle(false)

  const toggleModal = (kind: "followers" | "following" | "collectors") => {
    setModalKind(kind)
    toggleModalOpen(true)
  }

  const validCollectors: User[] =
    user.collectors
      ?.map((collector) => collector?.owner)
      .filter((owner): owner is User => owner !== null) || []

  validCollectors.sort((a, b) => (b.avatar ? 1 : -1))

  return (
    <>
      <FollowsDialog
        open={modalOpen}
        kind={modalKind}
        user={user}
        onOpenChange={toggleModalOpen}
      />
      <div
        className={cn(
          `
            grid h-12 grid-cols-2 rounded-md border border-sr-border-quaternary
            lg:h-16
          `,
          validCollectors.length > 0 &&
            `
              h-24
              lg:h-32
            `,
          className
        )}
      >
        <div
          className={`
            flex h-12 items-center px-5
            lg:h-16
          `}
        >
          <Stat
            label="Followers"
            value={user.total_followers_count}
            onClick={
              user.total_followers_count > 0
                ? () => toggleModal("followers")
                : undefined
            }
          />
        </div>
        <div
          className={`
            flex h-12 items-center border-l border-sr-border-quaternary px-5
            lg:h-16
          `}
        >
          <Stat
            label="Following"
            value={user.total_following_count}
            onClick={
              user.total_following_count > 0
                ? () => toggleModal("following")
                : undefined
            }
          />
        </div>
        {validCollectors.length > 0 && (
          <div
            className={`
              col-span-2 flex h-12 items-center border-t border-sr-border-quaternary px-5
              lg:h-16
            `}
          >
            <Stat
              label="Collectors"
              value={
                <UserList
                  className="lg:-mt-1.5"
                  count={user.total_collector_count}
                  users={validCollectors}
                  counter="left"
                />
              }
              onClick={
                user.total_collector_count > 0
                  ? () => toggleModal("collectors")
                  : undefined
              }
            />
          </div>
        )}
      </div>
    </>
  )
}
