"use client"

import { User } from "@/graphql/generated/apollo/graphql"
import { Tabs } from "@/ui/tabs"
import { usePathname, useRouter } from "next/navigation"
import { useAccount } from "wagmi"

interface ProfileHeroProps {
  profile: User
}

export default function ProfileNav({ profile }: ProfileHeroProps): JSX.Element {
  const router = useRouter()
  const tab = usePathname()
  const { address } = useAccount()

  const defaultTab = !profile.total_creations_count
    ? `/${profile.username}/collection`
    : tab == `/${profile.username}`
      ? `/${profile.username}/creations`
      : tab

  return (
    <>
      <Tabs.Provider
        defaultTab={defaultTab}
        onChange={(tab) => router.push(tab)}
        className={`
          mt-16
          lg:mt-18
        `}
      >
        <Tabs.List contained>
          {profile.total_creations_count > 0 && (
            <Tabs.Tab value={`/${profile.username}/creations`}>
              Creations ({profile.total_creations_count})
            </Tabs.Tab>
          )}
          <Tabs.Tab value={`/${profile.username}/collection`}>
            Collection ({profile.total_collections_count})
          </Tabs.Tab>
          {profile.ethereum_address.toLowerCase() ===
            address?.toLowerCase() && (
            <Tabs.Tab value={`/${profile.username}/favorites`}>
              Favorites ({profile.nft_likes_total_count})
            </Tabs.Tab>
          )}
          <Tabs.Tab value={`/${profile.username}/community/pool`}>
            Community
          </Tabs.Tab>
          {profile.total_collections_count > 0 && (
            <Tabs.Tab value={`/${profile.username}/insights`}>
              Insights
            </Tabs.Tab>
          )}
        </Tabs.List>
      </Tabs.Provider>
    </>
  )
}
