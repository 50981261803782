"use client"

import { User } from "@/graphql/generated/apollo/graphql"
import {
  ArtworkMedia,
  ArtworkDetails,
  ArtworkAuction,
  Erc721TokenExtended,
  ArtworkShowcaseSales,
} from "./base"
import useArtwork from "./useArtwork"
import { getMuxUrl } from "@/utils/mux"
import { CHAIN_ID } from "@/constants/environment"
import { chainIdToSlug } from "@/utils/chains"

const ProfileHeroCard = ({
  profile,
  artwork,
}: {
  profile: User
  artwork: Erc721TokenExtended
}): JSX.Element => {
  const { isAuction, isRunningAuction } = useArtwork(artwork)
  return (
    <article
      className={`
        group flex w-full flex-col
        lg:max-w-[560px]
      `}
    >
      <ArtworkMedia
        href={`/artwork/${chainIdToSlug(CHAIN_ID) || "eth"}/${artwork.contract_address}/${artwork.token_id}`}
        image={artwork.nft_image?.image_w800 ?? undefined}
        image_blurred={artwork.nft_image?.image_blurred_w390 ?? undefined}
        video={
          artwork.nft_image?.video_clipped_mux_playback_id
            ? getMuxUrl(
                artwork.nft_image.video_clipped_mux_playback_id,
                "medium"
              )
            : undefined
        }
      ></ArtworkMedia>

      <ArtworkDetails
        hideAvatars={
          profile.ethereum_address === artwork.erc721_creator?.address
        }
        showLike
        hideMobileContent
        artwork={artwork}
      />
      {isAuction && isRunningAuction ? (
        <ArtworkAuction artwork={artwork} auction={isAuction} />
      ) : (
        <ArtworkShowcaseSales artwork={artwork} />
      )}
    </article>
  )
}

export default ProfileHeroCard
