import { User } from "@/graphql/generated/apollo/graphql"
import { trpc } from "@/web/clients/trpc"
import { AvatarProfile } from "@/ui/avatarProfile"
import { Dialog } from "@/ui/dialog"
import { Loader } from "@/ui/loader"
import { useMemo } from "react"
import InfiniteScroll from "react-infinite-scroll-component"

interface FollowsDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  kind: "followers" | "following" | "collectors"
  user: User
  className?: string
}

const getUserForKind = (kind: string, user: any): User => {
  if (kind === "collectors") {
    return user.owner as User
  } else if (kind === "following") {
    return user.user_following as User
  }
  return user.user_follower as User
}

export const FollowsDialog = ({
  open,
  onOpenChange,
  kind,
  user,
}: FollowsDialogProps): JSX.Element => {
  const totalCount = useMemo(() => {
    if (kind === "collectors") {
      return user.total_collector_count
    } else if (kind === "following") {
      return user.total_following_count
    }
    return user.total_followers_count
  }, [kind, user])

  const myQuery = trpc.profile.getByUsername.useInfiniteQuery(
    {
      username: user.username,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      initialCursor: 1,
    }
  )

  const { data, hasNextPage, fetchNextPage } = myQuery

  // Function to filter known users and count unknown users based on the kind
  const { knownUsers, unknownCount } = useMemo(() => {
    const known: User[] = []
    let unknown = 0
    data?.pages.forEach((page) => {
      const items = page[kind] || []
      items.forEach((item) => {
        const user = getUserForKind(kind, item)
        if (user?.username) {
          known.push(user)
        } else {
          unknown++
        }
      })
    })
    return { knownUsers: known, unknownCount: unknown }
  }, [data?.pages, kind])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content title={`${kind} (${totalCount})`}>
        <ul>
          <InfiniteScroll
            dataLength={knownUsers.length}
            next={async () => {
              await fetchNextPage()
            }}
            hasMore={
              !!hasNextPage &&
              (data?.pages?.[data.pages.length - 1]?.[kind]?.length ?? 0) > 0
            }
            loader={
              <div className="flex justify-center pt-4">
                <Loader kind="simple" className="w-24" />
              </div>
            }
            scrollableTarget="scrollableDialogDiv"
            scrollThreshold={1}
          >
            {knownUsers.map((user) => (
              <li
                key={user.ethereum_address}
                className="flex justify-between py-2"
              >
                <AvatarProfile size="sm" user={user} />
                {/* todo check following status */}
              </li>
            ))}
            {unknownCount > 0 && (
              <li className="mt-2 flex justify-between border-t border-day-grey-4 pt-2 text-sm text-day-grey-1">
                <span className="">Anonymous</span>
                <span>{unknownCount}</span>
              </li>
            )}
          </InfiniteScroll>
        </ul>
      </Dialog.Content>
    </Dialog>
  )
}
